// eslint-disable-next-line import/no-unresolved
const jQuery = require("jqueryOriginal");

window.$ = window.$ || jQuery;
window.jQuery = window.jQuery || jQuery;

require("jquery-ujs");
require("bootstrap-v3/assets/javascripts/bootstrap/collapse");
require("bootstrap-v3/assets/javascripts/bootstrap/modal");
require("bootstrap-v3/assets/javascripts/bootstrap/tooltip");
require("bootstrap-v3/assets/javascripts/bootstrap/popover");
require("bootstrap-v3/assets/javascripts/bootstrap/dropdown");
require("lazysizes/lazysizes.min");

// eslint-disable-next-line global-require
window.$lightbox = window.$lightbox || require("lightbox2");

// eslint-disable-next-line import/no-unresolved
window.JiffyApi = require("jiffyApiOriginal");
// eslint-disable-next-line import/no-unresolved
window.JiffyStore = require("jiffyStoreOriginal");
// eslint-disable-next-line import/no-unresolved
window.JiffyAnalytics = require("jiffyAnalyticsOriginal");

(function module(root, factory) {
  factory(jQuery);
})(this, _$ => {
  // allow customization for lightbox
  window.$lightbox.option({
    alwaysShowNavOnTouchDevices: true
  });
});

module.exports = jQuery;
