export const isCustomizableMadePage = () => window.location.pathname.includes("custom-printed-");

export const isCustomizablePlp = () => window.location.href.includes("customizable");

export const isFlashPdp = () => window.location.href.includes("sameday=true");

export const isMadeSamplePdp = () =>
  isCustomizableMadePage() && window.location.href.includes("sample=true");

export const getFlashDeliveryText = (earliestDelivery, earliestDeliveryDay) => {
  if (isFlashPdp()) {
    return `Get ${earliestDeliveryDay}`;
  }
  return earliestDeliveryDay;
};

export const getPageSourceParam = () => {
  if (isFlashPdp()) {
    return "flash_on_made_dedicated_pdp";
  }
  if (isMadeSamplePdp()) {
    return "made_sample_pdp";
  }
  if (isCustomizableMadePage()) {
    return "made_pdp";
  }
  return "flash_on_blanks_pdp";
};

export default {};
