/* Not "jiffy-api" because it located in the same directory */
import { makePath, makeFullPath } from "common/utils/request/utils";

const makePathFn =
  path =>
  (options = {}) =>
    makePath(path, options);

const routes = Object.entries(window.rawRoutes).reduce((acc, [key, path]) => {
  if (
    [
      "editDekeoFunnelArtworkPath",
      "deliveryInfoDekeoFunnelInterviewPath",
      "zipcodeDekeoFunnelInterviewPath",
      "uploadCompleteFormTaxExemptionsPath"
    ].includes(key)
  ) {
    acc[key] = makePathFn(path);
  } else if (["stripeProviderGuestPath", "sezzleGuestPath"].includes(key)) {
    acc[key] = makeFullPath(path);
  } else {
    acc[key] = makePathFn(makeFullPath(path));
  }
  return acc;
}, {});

export default routes;
