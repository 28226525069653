export default {
  NAV_BAR_BACK: "NAV_BAR_BACK",
  SELECT_CHAT: "SELECT_CHAT",
  NAVIGATED_TO_CHECKOUT: "NAVIGATED_TO_CHECKOUT",
  NAVIGATED_TO_CHAT: "NAVIGATED_TO_CHAT",
  NAVIGATED_TO_INBOX_LIST: "NAVIGATED_TO_INBOX_LIST",
  NAVIGATED_TO_ACCOUNT_PAGE: "NAVIGATED_TO_ACCOUNT_PAGE",
  NAVIGATED_TO_PROOF_MANAGE: "NAVIGATED_TO_PROOF_MANAGE",
  NAVIGATED_TO_CUSTOMER_PROOF_MANAGE: "NAVIGATED_TO_CUSTOMER_PROOF_MANAGE",
  NAVIGATED_TO_ADDRESSES_PAGE: "NAVIGATED_TO_ADDRESSES_PAGE",
  NAVIGATED_TO_ADDRESS_EDIT_PAGE: "NAVIGATED_TO_ADDRESS_EDIT_PAGE",
  TOGGLE_DESKTOP_NOTIFICATIONS: "TOGGLE_DESKTOP_NOTIFICATIONS",
  SHOW_NAV_NEW_MESSAGE_BADGE: "SHOW_NAV_NEW_MESSAGE_BADGE",
  HIDE_NAV_NEW_MESSAGE_BADGE: "HIDE_NAV_NEW_MESSAGE_BADGE",
  SHOW_CONVERSATION_BADGE: "SHOW_CONVERSATION_BADGE",
  HIDE_CONVERSATION_BADGE: "HIDE_CONVERSATION_BADGE",
  FULL_LIST_REFRESH: "FULL_LIST_REFRESH",
  NAV_BAR_OPEN_SUBMENU: "NAV_BAR_OPEN_SUBMENU",
  NAV_BAR_CLOSE_SUBMENU: "NAV_BAR_CLOSE_SUBMENU",
  SHOW_BACK_BUTTON: "SHOW_BACK_BUTTON",
  HIDE_BACK_BUTTON: "HIDE_BACK_BUTTON",
  ADD_TO_CART: "ADD_TO_CART",
  ITEMS_ADDED_TO_CART: "ITEMS_ADDED_TO_CART",
  UPDATE_CART_QUANTITY: "UPDATE_CART_QUANTITY",
  NAVIGATED_TO_EDIT_QUOTE: "NAVIGATED_TO_EDIT_QUOTE",
  FORM_SUBMITTED: "FORM_SUBMITTED",
  DESIGNER_QUOTE_FORM_SUBMIT: "DESIGNER_QUOTE_FORM_SUBMIT",
  NAVIGATED_TO_EDIT_CART: "NAVIGATED_TO_EDIT_CART",
  NAVIGATED_TO_CONVERSATIONS: "NAVIGATED_TO_CONVERSATIONS",
  NAVIGATED_TO_DESIGNERS: "NAVIGATED_TO_DESIGNERS",
  NAVIGATED_TO_PRINTER: "NAVIGATED_TO_PRINTER",
  NAVIGATED_TO_PRODUCTS: "NAVIGATED_TO_PRODUCTS",
  NAVIGATED_TO_PRODUCT: "NAVIGATED_TO_PRODUCT",
  NAVIGATED_TO_CATEGORY: "NAVIGATED_TO_CATEGORY",
  NAVIGATED_TO_NOT_FOUND: "NAVIGATED_TO_NOT_FOUND",
  NAVIGATED_TO_FORBIDDEN: "NAVIGATED_TO_FORBIDDEN",
  NAV_BAR_OPEN: "NAV_BAR_OPEN",
  NAV_BAR_CLOSE: "NAV_BAR_CLOSE",
  NAVIGATED_TO_SUPPORT: "NAVIGATED_TO_SUPPORT",
  NAVIGATED_TO_PORTFOLIO_IMAGE_EDIT: "NAVIGATED_TO_PORTFOLIO_IMAGE_EDIT",
  TALKJS_PANEL_UPDATE_RECEIVED: "TALKJS_PANEL_UPDATE_RECEIVED",
  TOGGLE_FILTER: "TOGGLE_FILTER",
  TOGGLE_PRINTER_FILTER: "TOGGLE_PRINTER_FILTER",
  TOGGLE_MENU: "TOGGLE_MENU",
  TOGGLE_PRODUCT_COLOR_PICKER: "TOGGLE_PRODUCT_COLOR_PICKER",
  PRODUCT_COLOR_SELECTED: "PRODUCT_COLOR_SELECTED",
  PRODUCT_COLOR_SELECTED_FROM_SLIDER: "PRODUCT_COLOR_SELECTED_FROM_SLIDER",
  PRODUCT_STATIC_COLOR_SELECTED: "PRODUCT_STATIC_COLOR_SELECTED",
  SHOW_RECAPTCHA_MODAL: "SHOW_RECAPTCHA_MODAL",
  ZIPCODE_UPDATED: "ZIPCODE_UPDATED",
  ORDER_TIER_CHANGED: "ORDER_TIER_CHANGED",
  MOBILE_GRID_SUBMIT: "MOBILE_GRID_SUBMIT",
  PRODUCT_INPUT_CHANGED: "PRODUCT_INPUT_CHANGED",
  PRODUCT_INVENTORY_LOADED: "PRODUCT_INVENTORY_LOADED",
  CHECKOUT_SIGN_IN_BUTTON_CLICKED: "CHECKOUT_SIGN_IN_BUTTON_CLICKED",
  CHECKOUT_FORM_SUBMIT: "CHECKOUT_FORM_SUBMIT",
  READMORE_CLICKED: "READMORE_CLICKED",
  COLOR_PICKER_OPENED: "COLOR_PICKER_OPENED",
  COLOR_PICKER_CLOSED: "COLOR_PICKER_CLOSED",
  COLOR_PICKER_SCROLLED: "COLOR_PICKER_SCROLLED",
  QUOTE_SEARCH_STARTED: "QUOTE_SEARCH_STARTED",
  QUOTE_SEARCH_COMPLETED: "QUOTE_SEARCH_COMPLETED",
  QUOTE_SEARCH_COMPOSITIONS_LIST: "QUOTE_SEARCH_COMPOSITIONS_LIST",
  QUOTE_SEARCH_INTERMEDIATE_RESULT: "QUOTE_SEARCH_INTERMEDIATE_RESULT",
  QUOTE_SEARCH_CUSTOMER_COSTS: "QUOTE_SEARCH_CUSTOMER_COSTS",
  MOBILE_GRID_LOADED: "MOBILE_GRID_LOADED",
  ADDED_TO_FAVORITES: "ADDED_TO_FAVORITES",
  REMOVED_FROM_FAVORITES: "REMOVED_FROM_FAVORITES",
  COMPARABLES_LOADED: "COMPARABLES_LOADED",
  POPOVER_CANCELLED: "POPOVER_CANCELLED",
  POPOVER_CLOSED: "POPOVER_CLOSED",
  PUSHED_WEB_VITALS_METRICS: "PUSHED_WEB_VITALS_METRICS",
  SAVED_CART_SELECTED: "SAVED_CART_SELECTED",
  SAVED_CART_CREATED: "SAVED_CART_CREATED",
  SAVED_CART_CHANGED: "SAVED_CART_CHANGED",
  CRAFTERPAY_QUOTE_SAVED: "CRAFTERPAY_QUOTE_SAVED",
  CRAFTERPAY_ADDRESS_UPDATED: "CRAFTERPAY_ADDRESS_UPDATED",
  CHAT_REPLY_RECEIVED: "CHAT_REPLY_RECEIVED",
  CHAT_ERROR_RECEIVED: "CHAT_ERROR_RECEIVED",
  CHAT_SESSION_RETRIEVED: "CHAT_SESSION_RETRIEVED",
  BUNDLE_ADDED_TO_CART: "BUNDLE_ADDED_TO_CART",
  NEW_CONVERSATION: "NEW_CONVERSATION",
  OPEN_CHAT_WINDOW: "OPEN_CHAT_WINDOW",
  SHOW_HERCULES_CHAT: "SHOW_HERCULES_CHAT",
  CHAT_HISTORY_UPDATE: "CHAT_HISTORY_UPDATE",
  FILE_UPLOADED: "FILE_UPLOADED",
  PRODUCT_COLLECTION_PRODUCT_PRICE_CHANGE: "PRODUCT_COLLECTION_PRODUCT_PRICE_CHANGE",
  BULK_TABLE_UPDATE: "BULK_TABLE_UPDATE",
  SELECTED_VARIANT_CHANGED: "SELECTED_VARIANT_CHANGED"
};
